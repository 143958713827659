import React, { useState  } from 'react';
import Sidebar from '../../components/Sidebar';
import useUsers from '../../hooks/useUsers';
import PaginationControls from '../../components/PaginationControls';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { MdMailOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { CMS_ROUTES } from '../../../constants';
import { setEmailConfirmed, removePizzeriaByUser } from '../../services/api';
import Spinner from '../../components/Spinner';
import './UsersListPage.css';

const UsersListPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setfilters] = useState({query: null, verified: null});  
  const { usersData, loading: loadingUsers, error, setUsersData } = useUsers(page, limit, filters);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSearchClick = (filterData) => {
    setPage(1);
    const filterCopy = {...filters};
    
    filterCopy.verified = null;
    if(filterData.verified !== '-1'){
      filterCopy.verified = filterData.verified;
    }
    filterCopy.query = null; 
    if(filterData.query !== ''){
      filterCopy.query = filterData.query;
    }  
    filterCopy.email = null; 
    if(filterData.email !== ''){
      filterCopy.email = filterData.email;
    }      
    setfilters(filterCopy);
  };

  const changeStatus = async (id, active) => {
    try {
      setLoading(true);
      await setEmailConfirmed(id, !active);
      const copy = { ...usersData };
      copy.data.find((user) => user.id === id).email_confirmed = !active;
      setUsersData(copy); 
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  
  const deleteUser = async (id) => {
    try {
      setLoading(true);
      try {
        await removePizzeriaByUser(id);      
      } catch (error) {
        alert(error);
        setLoading(false);
      }          
      console.log()
      setUsersData({...usersData, data:usersData.data.filter((user) => user.id !== id)}); 
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="users-page cms">
      {loading && <Spinner />}
      <div className="main-content">
        <Sidebar />
        <div className="content">
          {loadingUsers && <Spinner />}
          {!loadingUsers && usersData && (
            <>
              <h2>Usuarios</h2>
              <hr />              
              <PizzeriaSearchFilter                                
                onSearchClick={handleSearchClick}
              />
              <div className="pizzerias container p-0 mt-5">
                <UsersTable data={usersData.data} onStatusToggle={changeStatus} onDelete={deleteUser}/>
                <PaginationControls
                  total={usersData.total}
                  page={page}
                  limit={limit}
                  onPageChange={handlePageChange}
                  onLimitChange={handleLimitChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const PizzeriaSearchFilter = ({onSearchClick }) => {
  const [verified, setVerified] = useState(null);
  const [query, setQuery] = useState('');
  const [email, setEmail] = useState();

  const handleSearch = () => {
    onSearchClick({email, query, verified});
  }

  const handleStatusChange = (event) => {
    setVerified(event.target.value);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="col">   
          <label for="query">Email</label>               
          <input
            type="email"
            placeholder="Buscar por email"
            value={email}
            onChange={handleEmailChange}
            className="form-control"
          />
        </div>
        <div className="col">   
          <label for="query">Nombre</label>               
          <input
            type="text"
            placeholder="Buscar por nombre"
            value={query}
            onChange={handleQueryChange}
            className="form-control"
          />
        </div>
        <div className="col">    
          <label for="search">Verificado</label>         
          <select value={verified} className="form-control" onChange={handleStatusChange}>
          <option value="-1">Todas</option>
          <option value="1">Si</option>
          <option value="0">No</option>
        </select>
        </div>                  
      </div>
      <div className='row'>
        <div className='col d-flex justify-content-end'>
          <button className="btn btn-custom-primary px-5" onClick={handleSearch}>Buscar</button>
        </div>
      </div>
    </>
  );
};

const UsersTable = ({ data, onStatusToggle, onDelete }) => {
  const navigate = useNavigate();
  return (    
    <div className="table-responsive pb-2">
      <table className="table table-sm">
        <thead className="table-dark">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Email</th>
            <th scope="col">Nombre</th>
            <th scope="col">Verificado</th>            
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.email}</td>
              <td>{user.contact_name}</td>              
              <td>{user.email_confirmed ? 'Si' : 'No'}</td>              
              <td>
                <div className="d-flex flex-row">
                  <ButtonTooltip icon={
                    <MdMailOutline
                      size={24}
                      color={user.email_confirmed ? 'green' : 'red'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => onStatusToggle(user.id, user.email_confirmed)}
                      className="mx-2"                                 
                      />  
                      } 
                      text={user.active ? 'Desactivar' : 'Activar'}
                    />   
                  {user.pizzeria_id &&
                    <ButtonTooltip icon={                              
                      <FaPencilAlt
                        size={24}
                        color='grey'
                        style={{ cursor: 'pointer' }}                                        
                        onClick={() => navigate(`${CMS_ROUTES.EDIT_PIZZERIA}/${user.pizzeria_id}`)}
                        className="mx-2"
                      />   
                      } 
                      text="Editar"
                    />    
                  }
                  <ButtonTooltip icon={                              
                    <FaTrashAlt
                      size={24}
                      color='grey'
                      style={{ cursor: 'pointer' }}                                        
                      onClick={() => onDelete(user.id)}
                      className="mx-2"
                    />   
                    } 
                    text="Editar"
                  />    
                </div>                               
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>    
  );
};

const Tooltip = ({ text, maxLength }) => {
  const truncatedText = text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  return (
    <div className="custom-tooltip">
      {truncatedText}
      <span className="tooltiptext">{text}</span>
    </div>
  );
};

const ButtonTooltip = ({ icon, text }) => {  
  return (
    <div className="button custom-tooltip">
      {icon}
      <span className="tooltiptext">{text}</span>
    </div>
  );
};

export default UsersListPage;

import React, { useState } from 'react';
import Sidebar from '../../components/Sidebar';
import usePizzerias from '../../hooks/usePizzerias';
import PaginationControls from '../../components/PaginationControls';
import { MdPowerSettingsNew } from 'react-icons/md';
import { FaPencilAlt } from 'react-icons/fa';
import { setPizzeriaStatus } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { CMS_ROUTES } from '../../../constants';
import Spinner from '../../components/Spinner';
import './PizzeriasListPage.css';

const PizzeriasListPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setfilters] = useState({ query: null, status: null });
  const { pizzeriasData, loading: loadingPizzeria, error, setPizzeriasData } = usePizzerias(page, limit, filters);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSearchClick = (filterData) => {
    setPage(1);
    const filterCopy = { ...filters };

    filterCopy.status = null;
    if (filterData.status !== '-1') {
      filterCopy.status = filterData.status;
    }
    filterCopy.query = null;
    if (filterData.query !== '') {
      filterCopy.query = filterData.query;
    }
    setfilters(filterCopy);
  };

  const changeStatus = async (id, active) => {
    try {
      setLoading(true);
      await setPizzeriaStatus(id, !active);
      const copy = { ...pizzeriasData };
      copy.data.find((pizzeria) => pizzeria.id === id).active = !active;
      setPizzeriasData(copy);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="pizzerias-page cms">
      {loading && <Spinner />}
      <div className="main-content">
        <Sidebar />
        <div className="content">
          {loadingPizzeria && <Spinner />}
          {!loadingPizzeria && pizzeriasData && (
            <>
              <h2>Pizzerias</h2>
              <hr />
              <PizzeriaSearchFilter
                onSearchClick={handleSearchClick}
              />
              <div className="pizzerias container p-0 mt-5">
                <PizzeriaTable data={pizzeriasData.data} onStatusToggle={changeStatus} />
                <PaginationControls
                  total={pizzeriasData.total}
                  page={page}
                  limit={limit}
                  onPageChange={handlePageChange}
                  onLimitChange={handleLimitChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const PizzeriaSearchFilter = ({ onSearchClick }) => {
  const [status, setStatus] = useState(null);
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    onSearchClick({ query, status });
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <label for="query">Nombre</label>
          <input
            type="text"
            placeholder="Buscar por nombre"
            value={query}
            onChange={handleQueryChange}
            className="form-control"
          />
        </div>
        <div className="col">
          <label for="search">Estado</label>
          <select value={status} className="form-control" onChange={handleStatusChange}>
            <option value="-1">Todas</option>
            <option value="1">Activas</option>
            <option value="0">Inactivas</option>
          </select>
        </div>
      </div>
      <div className='row'>
        <div className='col d-flex justify-content-end'>
          <button className="btn btn-custom-primary px-5" onClick={handleSearch}>Buscar</button>
        </div>
      </div>
    </>
  );
};

const PizzeriaTable = ({ data, onStatusToggle }) => {
  const navigate = useNavigate();
  return (
    <div className="table-responsive pb-2">
      <table className="table table-sm">
        <thead className="table-dark">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Nombre</th>
            <th scope="col">APYCE</th>
            <th scope="col">Dirección</th>
            <th scope="col">Tiene sucursales</th>
            <th scope="col">Activo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((pizzeria) => (
            <tr key={pizzeria.id}>
              <td>{pizzeria.id}</td>
              <td>{pizzeria.fantasy_name}</td>
              <td>{pizzeria.apyce_member ? 'Si' : 'No'}</td>
              <td>
                <Tooltip text={pizzeria.address} maxLength={40} />
              </td>
              <td>{pizzeria.has_branches ? 'Si' : 'No'}</td>
              <td>{pizzeria.active ? 'Si' : 'No'}</td>
              <td>
                <div className="d-flex flex-row">
                  <ButtonTooltip icon={
                    <MdPowerSettingsNew
                      size={24}
                      color={pizzeria.active ? 'green' : 'red'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => onStatusToggle(pizzeria.id, pizzeria.active)}
                      className="mx-2"
                    />
                  }
                    text={pizzeria.active ? 'Desactivar' : 'Activar'}
                  />
                  <ButtonTooltip icon={
                    <FaPencilAlt
                      size={24}
                      color='grey'
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`${CMS_ROUTES.EDIT_PIZZERIA}/${pizzeria.id}`)}
                      className="mx-2"
                    />
                  }
                    text="Editar"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Tooltip = ({ text, maxLength }) => {
  const truncatedText = text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  return (
    <div className="custom-tooltip">
      {truncatedText}
      <span className="tooltiptext">{text}</span>
    </div>
  );
};

const ButtonTooltip = ({ icon, text }) => {
  return (
    <div className="button custom-tooltip">
      {icon}
      <span className="tooltiptext">{text}</span>
    </div>
  );
};

export default PizzeriasListPage;

import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,  
  FacebookIcon,
  TwitterIcon
} from 'react-share';

const ShareButtons = ({ url, title }) => {
  return (
    <div className='mt-2 d-flex justify-content-end'>
      <FacebookShareButton className='mx-1' url={url} quote={title}>
        <FacebookIcon size={40} round />
      </FacebookShareButton>

      <TwitterShareButton className='mx-1' url={url} title={title}>
        <TwitterIcon size={40} round />
      </TwitterShareButton>
    </div>
  );
};

export default ShareButtons;
